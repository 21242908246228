.row {
  display: flex;
  gap: 20px;
  align-items: start;
  margin-top: 20px;

  &:first-of-type {
    margin-top: 0;
  }

  label {
    display: flex;
    gap: 20px;
    align-items: start;
  }
}

.input, .textarea {
  display: block;
  width: 500px;
  min-height: 120px;
  padding: 10px 16px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-sizing: border-box;
}

.input {
  min-height: auto;
  height: 30px;
}

.labelText {
  font-size: 20px;
  width: 200px;
}