.wrapper {
  margin: 100px auto 0;
  max-width: 360px;
  font-size: 16px;
  text-align: center;
}

.title {
  margin: 20px 0 0;
  padding: 0 31px 0 0;
  line-height: 1;
  font-size: 21px;
  font-weight: 500;
  text-align: right;
}

.errorCode {
  margin: 0 0 20px;
  line-height: 0.8;
  font-size: 200px;
  font-weight: 500;
}