.wrapper {
  position: relative;
  padding: 20px;
  font-size: 16px;
}

.questionerDetails {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.leftPart {
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rightPart {
  width: 50%;
  flex-grow: 1;
}

.ingredientsTitle {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.infoRow {
  font-size: 16px;
  font-weight: 500;
}

.menu {
  margin-top: 20px;
  width: 100%;
  border: none;
}

.ingredientsBlock {
  margin-top: 20px;
}

.dishName {
  font-size: 20px;
  font-weight: bold;
}

.ingredientTable {
  margin-top: 10px;
}

.preline {
  white-space: pre-line;
  margin-top: 50px;
}

.chatGPTBlock {
  margin-top: 40px;

  button {
    margin-top: 20px;
  }
}

.gptInfoBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}

.typeTitle {
  font-weight: bold;
}

.totalPrice {
  margin-top: 30px;
  font-weight: bold;
  font-size: 28px;
}

.gptInfo {
  font-size: 16px;
  margin-top: 4px;
}

.prompts {
  display: flex;
  gap: 20px;
  margin-top: 10px;

  .prompt, .answer {
    max-width: 50%;
    background-color: #f4f4f4;
    border-radius: 20px;
    padding: 20px;
    white-space: pre-line;
  }

  .answer {
    width: 50%;
  }

  .promptBlock {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 2px solid #333;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
  }

  .promptTitle {
    margin-top: 10px;
    font-weight: bold;
  }
}