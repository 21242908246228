.mealBlock {
  margin-top: 20px;
  border: 1px solid var(--gray-2);
  padding: 20px;
  border-radius: 10px;

  &:first-child {
    margin-top: 0;
  }
}

.mealName {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.dish {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 4px;
  background-color: #f0f0f0;
  cursor: grab;
}