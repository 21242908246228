.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: 100%;
  height: 56px;
  margin: 0 auto;
  padding: 8px 16px;
  background-color: var(--dark-gray);
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
}

.logo {
  color: var(--white);
  text-decoration: none;
}

.menuWrapper {
  margin-left: 20px;
}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.menuItem {
  margin-left: 24px;
}

.menuItem:first-child {
  margin-left: 0;
}

.menuLink, .logout {
  color: var(--transparent);
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--transparent-2);
  }
}

.menuLink:hover, .logout:hover {
  color: var(--transparent-2);
}

.menuLink:global(.active) {
  color: var(--white);
}
