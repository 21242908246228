.link {
  color: var(--blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.tools {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.table {
  td, th {
    &:nth-child(2) {
      width: 150px;
    }

    &:nth-child(3) {
      white-space: nowrap;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:nth-child(4) {
      width: 200px;
    }
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.addDish {
  display: block;
  width: 140px;
  cursor: pointer;
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 16px;
  text-decoration: none;
  text-align: center;
}

.bannerImage {
  height: 70px;
  object-fit: contain;
}