.footer {
  margin-top: 40px;
  font-size: 20px;
}

.menuWrapper {
  border-radius: 10px;
  overflow: hidden;
  background-color: #eee;
  margin-top: 20px;
  
  &:first-child {
    margin-top: 0;
  }
}

.header {
  color: #fff;
  font-size: 24px;
  height: 50px;
  padding: 0 20px;
  line-height: 50px;
  background-color: #d53475;
  box-sizing: border-box;
}

.body {
  padding: 20px;
  display: flex;
  gap: 30px;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }
}

.totalNutrients {
  padding: 18px;
  border-bottom: 1px solid #ccc;

  .nutrients {
    margin-top: 0;
    font-size: 22px;
    line-height: 32px;
  }
}

.nutrientRow {
  &:first-letter {
    text-transform: uppercase;
  }
}

.imageBlock {
  width: 100px;
  height: 100px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mealName {
  margin-bottom: 10px;
  font-size: 16px;
  color: #777;

  &:first-letter {
    text-transform: uppercase;
  }
}

.dishName {
  color: #111;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }
}

.nutrients {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #666;
}

.title {
  margin-top: 10px;
  font-weight: bold;
}

.componentData {
  font-size: 18px;
}

.componentName {
  font-weight: bold;
  margin-top: 5px;
}

.ingredientItem {
  margin-top: 5px;
}