.overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, .5);
}

.modal {
  position: absolute;
  min-width: 500px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 5px;
  outline: none;
}

.header {
  height: 68px;
  padding: 16px; 
  box-sizing: border-box;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #e9ecef;
}

.close {
  width: 15px;
  height: 15px;
  float: right;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  cursor: pointer;
}

.body {
  display: flex;
  justify-content: right;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid #e9ecef;
}

.saveButton, .closeButton {
  display: inline-block;
  height: 36px;
  padding: 0 12px;
  line-height: 36px;
  border: 1px solid transparent;
  text-align: center;
  font-weight: 400;
  color: var(--white);
  font-size: 16px;
  border-radius: 4px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  cursor: pointer;
}

.saveButton {
  background-color: #28a745;
  border-color: #28a745;

  &:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
}

.closeButton {
  background-color: #dc3545;
  border-color: #dc3545;

  &:hover {
    background-color: #c82333;
    border-color: #bd2130;
  }
}