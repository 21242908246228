.wrapper, .authWrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
  box-sizing: border-box;
}

.wrapper {
  grid-template-rows: 1fr auto;
  background-color: #eee;
}
