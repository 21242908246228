.wrapper {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.typeTitle {
  font-weight: bold;
}

.modelBlock {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #ced4da;

  .select {
    width: 200px;
  }
}

.promptItem {
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
}

.roleBlock {
  display: flex;
  gap: 20px;
  align-items: center;
}

.input, .select, .textarea {
  display: block;
  width: 400px;
  height: 37px;
  padding: 0 16px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-sizing: border-box;
}

.select {
  width: 130px;
}

.textarea {
  height: 200px;
  width: 100%;
  padding: 10px 16px;
}

.promptButtons {
  display: flex;
  gap: 10px;
}

.savePrompt {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.answersTitle {
  margin-top: 20px;
  font-size: 24px;
}

.answersBlock {
  margin-top: 20px;
}

.answerItem {
  white-space: pre-line;
  border-top: 1px solid #ccc;
  padding: 10px 0;

  &:first-child {
    border-top: 0;
  }
}

.answerModel {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
}