.wrapper {
  background-color: var(--light-gray);
}

.form {
  width: 330px;
  margin: 40px auto 0;
  padding: 15px;
  box-sizing: border-box;
}

.title {
  margin-bottom: 8px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  font-size: 32px;
}

.input {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0.375rem 0.75rem;
  margin-top: 4px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-sizing: border-box;
}

.input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.input::placeholder {
  color: var(--gray);
}

.submit {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 8px 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  background-color: var(--blue);
  border: none;
  color: #fff;
  cursor: pointer;
}

.submit:hover {
  background-color: #0069d9;
}
