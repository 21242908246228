.link {
  color: var(--blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.tools {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dishTypeSelect {
  display: block;
  width: 200px;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.addDish {
  display: block;
  width: 140px;
  cursor: pointer;
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 16px;
  text-decoration: none;
  text-align: center;
}

.table {
  th, td {
    vertical-align: top;

    &:nth-child(3) {
      min-width: 115px;
    }

    &:nth-child(4) {
      min-width: 235px;
      white-space: nowrap;
    }
  }
}

.ingredientItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  margin-top: 5px;

  &:first-child {
    margin-top: 0;
  }
}

.ingredientsBlock {
  margin-top: 5px;

  &:first-child {
    margin-top: 0;
  }
}

.instructionItem {
  white-space: pre-wrap;
  margin-top: 5px;

  &:first-child {
    margin-top: 0;
  }
}

.componentName {
  font-weight: bold;
  margin-bottom: 5px;
}