.wrapper {
  position: relative;
  padding: 0 20px;
  font-size: 16px;
}

.questionerDetails {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.leftPart {
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rightPart {
  width: 50%;
  flex-grow: 1;
}

.ingredientsTitle {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.infoRow {
  font-size: 16px;
  font-weight: 500;
}

.menu {
  margin-top: 20px;
  width: 100%;
  border: none;
}

.ingredientsBlock {
  margin-top: 20px;
}

.dishName {
  font-size: 20px;
  font-weight: bold;
}

.ingredientTable {
  margin-top: 10px;
}

.preline {
  white-space: pre-line;
  margin-top: 50px;
}