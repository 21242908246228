.wrapper {
  padding: 20px;
  display: flex;
  box-sizing: border-box;
  height: calc(100vh - 120px);
  justify-content: center;
  align-items: center;
}

.video {
  max-width: 100%;
  max-height: 100%;
}