:root {
  --black: #202020;
  --black-2: #1C171E;
  --brown: #C38F2A;
  --blue: #037bff;
  --dark-blue: #0069d9;
  --dark-gray: #343a40;
  --red: #dc3545;
  --dark-red: #c82333;
  --yellow: #ffc107;
  --dark-yellow: #e0a800;
  --green: #28a745;
  --dark-green: #218838;
  --gray: #8D8D8D;
  --gray-2: #DCDCDC;
  --light-gray: #eee;
  --light-gray-2: #dee2e6;
  --lighter-gray: #f5f5f5;
  --purple: #3E0075;
  --purple-2: #3F0275;
  --red: #dc3545;
  --transparent: rgba(255,255,255,.5);
  --transparent-2: rgba(255,255,255,.75);
  --white: #FFF;
  --content-width: 1410px;
  --content-padding: 16px;
  --content-padding-mobile: 28px;
  --header-height: 98px;
  --header-height-mobile: 178px;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

p, h1, h2 {
  margin: 0;
}

button {
  border: none;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}

.wrapper {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

h1 {
  font-size: 28px;
  padding: 0 0 20px;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 1px solid var(--light-gray-2);
  border-collapse: collapse;

  thead th, thead td {
    border-bottom-width: 2px;
    padding: 12px;
    text-align: left;
  }

  th, td {
    border: 1px solid var(--light-gray-2);
    padding: 12px;
  }
}

.button {
  display: inline-block;
  height: 37px;
  padding: 0 12px;
  border: none;
  border-radius: 4px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 37px;
  text-align: center;
  white-space: nowrap;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:disabled {
    opacity: .4;
    cursor: not-allowed;
  }
}

.blue-button {
  background-color: var(--blue);
  &:hover {
    background-color: var(--dark-blue);
  }
}

.green-button {
  background-color: var(--green);

  &:hover {
    background-color: var(--dark-green);
  }
}

.red-button {
  background-color: var(--red);

  &:hover {
    background-color: var(--dark-red);
  }
}

.yellow-button {
  background-color: var(--yellow);
  color: var(--black);

  &:hover {
    background-color: var(--dark-yellow);
  }
}

.controls .button {
  margin-left: 3px;
}

.controls .button:first-child {
  margin-left: 0;
}