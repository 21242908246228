.input, .select {
  display: block;
  width: 100%;
  height: 37px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-sizing: border-box;
}

.alternativeBlock {
  display: flex;
  gap: 10px;
  align-items: center;

  .select {
    width: calc(100% - 30px);
    margin-bottom: 0;
  }
}

.amount {
  display: none;
}

.withAmount {
  .select {
    width: calc(100% - 530px);
  }

  .amount {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
  
    .input {
      width: 150px;
    }
  }
}

.unitType {
  font-size: 16px;
  &::first-letter {
    text-transform: uppercase;
  }
}

.isRequired {
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    font-size: 16px;
    user-select: none;
  }

  input {
    width: 20px;
    height: 20px;
  }
}

.addAlternative {
  width: 18px;
  height: 17px;
  line-height: 14px;
  font-size: 32px;
  font-weight: 700;
  cursor: pointer;
}

.alternativeList {
  margin-top: 15px;
}

.selectedAlternative {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
  margin-top: 5px;
  
  &:first-child {
    margin-top: 0;
  }
}

.ingredientUnitType {
  margin-left: 10px;
  &::first-letter {
    text-transform: uppercase;
  }
}

.delete {
  width: 11px;
  height: 11px;
  line-height: 11px;
  font-size: 20px;
  font-weight: 700;
  color: #F00;
  cursor: pointer;
}

.edit {
  /* position: relative; */
  /* left: -5px; */
  width: 15px;
  height: 15px;
  line-height: 18px;
  font-size: 14px;
  cursor: pointer;
}