.wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 38px;
}

.fileInput {
  display: none;
}

.uploadWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.upload {
  display: block;
  width: 70px;
  cursor: pointer;
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background-color: var(--red);
  color: var(--white);
  font-size: 16px;
  text-decoration: none;
  text-align: center;
}