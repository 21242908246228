.wrapper {
  padding: 16px;
}

.row {
  span {
    font-size: 14px;
  }

  .input, .select {
    margin-top: 5px;
  }
}

.input, .select {
  display: block;
  width: 100%;
  height: 37px;
  padding: 0 16px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-sizing: border-box;
}

.optionals {
  font-size: 20px;
}

.nutrients {
  display: flex;
  gap: 10px;
  margin-top: 5px;

  .input {
    width: 174px;
  }
}

.alternative {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
}